// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAh1CW_DchwiWxUw5GUv1ArtfPiVb7HXPs",
  authDomain: "ecomdbclone6.firebaseapp.com",
  projectId: "ecomdbclone6",
  storageBucket: "ecomdbclone6.firebasestorage.app",
  messagingSenderId: "537260321244",
  appId: "1:537260321244:web:89e27a3bd0bc486ff18719"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;